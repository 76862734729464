import './App.css';
import logo from "./ScieverIncLogoD.svg"

function App() {
    console.log(process.env.REACT_APP_API_KEY)
    return (
        <div className="App">
            <img src={logo} alt="Logo" />

            <h5>Under Construction</h5>
            <h1>Chatbot Coming Soon.............</h1>
        </div>
    );
}

export default App;
